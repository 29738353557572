import React from "react"

import SEO from "../components/seo"
import PageMain from "../components/page-main"
import MainHeadline from "../components/main-headline"
import ContentSection from "../components/content-section"
import ContentNote from "../components/content-note"
import ContentIntro from "../components/content-intro"
import ContentList from "../components/content-list"
import Link from "../components/link"
import ColorBlock from "../components/color-block"

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not Found" />
      <PageMain>
        <ContentSection>
          <ColorBlock />
          <MainHeadline>Oh, The Horror</MainHeadline>
          <ContentIntro>This place doesn't exist. Should it?</ContentIntro>
        </ContentSection>
        <ContentSection>
          <ContentNote>Then what</ContentNote>
          <h2 className="subheader">Three step process:</h2>
          <ContentList>
            <li>Check the URL for typos.</li>
            <li>
              Let me know with an email to{" "}
              <strong>
                <Link href="mailto:hello@torkil.tech">hello@torkil.tech</Link>
              </strong>
              .
            </li>
            <li>Go to my blog to find something else of interest.</li>
          </ContentList>
        </ContentSection>
      </PageMain>
    </>
  )
}

export default NotFoundPage
